import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AUTH_ROLES,
  AUTH_TOKEN,
  AUTH_TYPE,
  AUTH_ID,
  AUTH_NAME,
  VENDOR_TYPE,
  VENDOR_CATEGORIES,
  AUTH_USER,
  AUTH_USER_DETAILS,
  AUTH_ACCOUNT_DETAILS,
  AUTH_GST_DETAILS,
} from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import AuthService from "services/AuthService";
import { ADMIN_TYPE } from "../../constants/AuthConstant";
import { getAuth } from "firebase/auth";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  userId: localStorage.getItem(AUTH_ID) || null,
  userName: localStorage.getItem(AUTH_NAME) || null,
  token: localStorage.getItem(AUTH_TOKEN) || null,
  type: localStorage.getItem(AUTH_TYPE) || "",
  roles: JSON.parse(localStorage.getItem(AUTH_ROLES)) || {},
  user: JSON.parse(localStorage.getItem(AUTH_USER)) || {},
  userDetails: JSON.parse(localStorage.getItem(AUTH_USER_DETAILS)) || {},
  accountDetails: localStorage.getItem(AUTH_ACCOUNT_DETAILS) ? JSON.parse(localStorage.getItem(AUTH_ACCOUNT_DETAILS)) : {} || {},
  gstDetails: JSON.parse(localStorage.getItem(AUTH_GST_DETAILS)) || {},
};

console.log('initial state rrr',initialState)

export const signIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    const { authinfo, password } = data;
    try {
      const response = await AuthService.login({ authinfo, password });

      if (response.data.user.type !== VENDOR_TYPE) {
        return rejectWithValue("Invalid Login");
      }

      // const { sessionToken: token, type, roles, id, user } = response.data;
      const {
        sessionToken: token,
        user: { type, roles, id, username, vendorCategories },
      } = response.data;
      localStorage.setItem(
        AUTH_USER,
        JSON.stringify(response.data.user.subscription)
      );
      localStorage.setItem(
        AUTH_USER_DETAILS,
        JSON.stringify(response.data.user)
      );
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_TYPE, response.data.user.type);
      localStorage.setItem(AUTH_ID, response.data.user._id);
      localStorage.setItem(AUTH_NAME, response.data.user.username);
      localStorage.setItem(
        AUTH_ACCOUNT_DETAILS,
        response.data.user.accountDetails ? JSON.stringify(response.data.user.accountDetails) : {}
      );
      localStorage.setItem(
        AUTH_GST_DETAILS,
        response.data.user.GST ? JSON.stringify(response.data.user.GST) : {}
      );

      console.log("user in login 333", response.data.user);

      let mappedRoles = {};
      if (roles) {
        mappedRoles = {};
        roles.forEach(({ key, ...role }) => {
          mappedRoles[key] = role;
        });
      }
      localStorage.setItem(AUTH_ROLES, JSON.stringify(mappedRoles));
      return { token, type, roles: mappedRoles, id };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// export const updateUserDetails = (state, action) => {
//   state.accountDetails = action.payload.accountDetails;
//   state.gstDetails = action.payload.gstDetails;
//   localStorage.setItem(AUTH_USER_DETAILS, JSON.stringify(action.payload));
//   localStorage.setItem(
//     AUTH_ACCOUNT_DETAILS,
//     JSON.stringify(action.payload.accountDetails)
//   );
//   localStorage.setItem(
//     AUTH_GST_DETAILS,
//     JSON.stringify(action.payload.gstDetails)
//   );
// };

export const signUp = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.register({ email, password });
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  const response = await FirebaseService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_TYPE);
  localStorage.removeItem(AUTH_ROLES);
  localStorage.removeItem(AUTH_ID);
  localStorage.removeItem(AUTH_NAME);
  return response.data;
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.userId = action.payload.id;
      state.token = action.payload.token;
      state.type = action.payload.type;
      state.userName = action.payload.user;
      state.roles = action.payload.roles;
      state.vendorCategories = action.payload.vendorCategories;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.type = null;
      state.userName = null;
      state.userId = null;
      state.roles = {};
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
    updateUserDetails: (state, action) => {
      console.log('entered to update')
      state.accountDetails = action.payload.accountDetails;
      state.gstDetails = action.payload.gstDetails;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phoneNo = action.payload.phoneNo;
      state.userId = action.payload.userId;
      localStorage.setItem(AUTH_USER_DETAILS, JSON.stringify(action.payload));
      localStorage.setItem(
        AUTH_ACCOUNT_DETAILS,
        JSON.stringify(action.payload.accountDetails)
      );
      localStorage.setItem(
        AUTH_GST_DETAILS,
        JSON.stringify(action.payload.gstDetails)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.userId = action.payload.id;
        state.token = action.payload.token;
        state.userName = action.payload.user;
        state.type = action.payload.type;
        state.roles = action.payload.roles;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.userId = null;
        state.type = null;
        state.userName = null;
        state.roles = {};
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.type = null;
        state.userName = null;
        state.roles = {};
        state.userId = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  updateUserDetails,
} = authSlice.actions;

export default authSlice.reducer;
