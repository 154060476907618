export const UserKey = 'user';
export const CustomerKey = 'customer';
export const VendorKey = 'vendor';
export const bookingKey = 'booking';
export const categoryKey = 'category';
export const transactionsKey = 'transactions';
export const amenitiesKey = 'amenities';
export const categoryQuestionsKey = 'categoryQuestions';
export const ListingKey = 'listingPage';
export const cartKey = 'cart';
export const enquiryKey = 'enquiry';
export const calendarKey = 'calendar';
export const ratingsKey = 'ratings';
export const vendorCartKey = 'vendorCart';
export const subscriptionsKey = 'Subscriptions';
export const expensesKey = 'expenses';
export const receiptsKey = 'receipts';
export const MakeKey = 'make';
export const ModelKey = 'model';
export const EmployeeTypeKey = 'employee-type';
export const TransmissionKey = 'transmission';
export const FuelTypeKey = 'fuel-type';
export const BodyTypeKey = 'body-type';
export const VehicleTypeKey = 'vehicle-type';
export const ColorKey = 'color';
export const VersionKey = 'version';
export const LeadKey = 'lead';
export const QuestionCategoryKey = 'question-category';
export const InventoryQuestionKey = 'inventory-question';
export const InventoryKey = 'inventory';
export const StateKey = 'state';
export const CityKey = 'city';
export const MainClientKey = 'main-client';
export const ClientKey = 'client';
export const ServicePriceKey = 'service-price';
export const SettingsKey = 'settings';
export const InventoryAgentKey = 'inventory-agent';
export const InvoiceKey = 'invoice';
export const ThirdPartyKey = 'third-party';
export const ThirdPartyEmployeeKey = 'third-party-employee';
export const ClientExecutiveKey = 'client-executive';
export const RegionKey = 'region';
export const TransactionKey = 'transaction';
export const BillsKey = 'bills';
export const VehicleInspectionReportKey = 'vehicle-inspection-report';
export const InventoryOperationsKey = 'inventory-operations';
export const InventoryQcKey = 'inventory-qc';
export const InventoryPricingKey = 'inventory-pricing';
export const vendorPaymentsKey = 'vendor-payments';
export const bookingDateChangeKey = 'booking-date-change-request';